<template>
  <div>
    <project-view v-if="filter.type === 1" :list="list" :type="getType" :headers="headers" :filter="reportFilter" :timerFormSettings="timerFormSettings"></project-view>
    <customer-view v-if="filter.type === 3" :list="list" :type="getType" :headers="headers" :filter="reportFilter" :timerFormSettings="timerFormSettings"></customer-view>
    <employee-view v-if="filter.type === 4" :list="list" :type="getType" :headers="headers" :filter="reportFilter" :timerFormSettings="timerFormSettings"></employee-view>
    <!-- <absence-employee-view v-if="filter.type === 4" :list="list" :type="getType" :headers="headers"></absence-employee-view> -->
  </div>
</template>
<script>
export default {
  props: ['list', 'filter', 'reportFilter', 'headers', 'timerFormSettings'],
  components: {
    'project-view': () => import('./ProjectView'),
    'customer-view': () => import('./CustomerView'),
    'employee-view': () => import('./EmployeeView')
  },
  computed: {
    getType () {
      if (this.reportFilter.showonlysum && !this.reportFilter.showestimation) return 'sum'
      else if (!this.reportFilter.showonlysum && this.reportFilter.showestimation) return 'estimation'
      else if (this.reportFilter.showestimation && this.reportFilter.showonlysum) return 'sum_both'
      else return 'all'
    }
  }
}
</script>
<style>
.report tbody td {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  height: 38px !important;
}
</style>
